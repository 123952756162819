import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';
export const packAPI = {
  getPack: topic.packTopic + '/getPack',
  getDeptInfo: topic.packTopic + '/getDeptInfo',
  deletePackByIds: params => postNoCatch(`${topic.packTopic}/deletePackByIds`, params),
  editPack: params => postNoCatch(`${topic.packTopic}/editPack`, params),
  getPackById: params => getNoCatch(`${topic.packTopic}/getPackById`, params),
  addPack: params => postNoCatch(`${topic.packTopic}/addPack`, params),
  getTranPordById: params => getNoCatch(`${topic.packTopic}/getTranPordById`, params)
};
