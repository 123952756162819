<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="packForm"
        @openEdit="
          isShow = false;
          $emit('isShow', false);
        "
        @closeEdit="closeEdit"
        @submit="submit('packForm')"
      />
      <div class="vd_export">
        <el-button type="primary" size="small" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        <el-button v-if="isShow" size="small" type="primary" @click="clickExportPartExcel(1)">导出excel</el-button>
        <el-button v-if="isShow" size="small" type="info" @click="clickExportPartExcel(0)">打印预览</el-button>
      </div>
      <el-col :md="4" class="vd_export2">
        <span style="margin-right: 2px">出运发票号:</span>
        <el-input size="small" style="width: 150px" :value="helper.transverseTranNo(packForm.tran_no)" disabled show-word-limit></el-input>
      </el-col>
    </div>
    <el-form class="form" ref="packForm" :model="packForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <!--<el-col :md="8">-->
        <!--  <el-form-item label="供应商简称" prop="supp_id">-->
        <!--    <el-select disabled @change="suppIdChange" size="mini" filterable v-model="packForm.supp_id" placeholder="请选择供应商简称">-->
        <!--      <el-option v-for="item in suppOptionList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>-->
        <!--    </el-select>-->
        <!--  </el-form-item>-->
        <!--</el-col>-->
        <el-col :md="8">
          <el-form-item label="关单号" prop="customs_no">
            <el-input size="mini" maxlength="20" v-model="packForm.customs_no" placeholder="请填写关单号" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <!--<el-form-item label="经办人" :rules="[{ required: true }]">-->
          <!--  <el-select disabled filterable v-model="packForm.stff_name" placeholder="请选择经办人" size="mini" clearable>-->
          <!--    <el-option v-for="item in userList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>-->
          <!--  </el-select>-->
          <!--</el-form-item>-->
          <el-form-item label="离港日" prop="tran_spdate">
            <el-date-picker disabled v-model="packForm.tran_spdate" value-format="timestamp" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="经办人" prop="agent_id">
            <el-select filterable v-model="packForm.agent_id" placeholder="请选择经办人" size="mini" clearable>
              <el-option v-for="item in userList" :key="item.stff_id + item.stff_name" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aname">
            <el-select disabled filterable v-model="packForm.cptt_name" placeholder="请选择公司抬头" size="mini" clearable>
              <el-option v-for="item in CpttsOptins" :key="item.cptt_id + item.cptt_name" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="装箱日期" prop="pack_date">
            <el-date-picker v-model="packForm.pack_date" value-format="timestamp" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <!--<el-col :md="8">-->
        <!--  <el-form-item label="箱型" prop="box_type">-->
        <!--    <el-input size="mini" maxlength="20" v-model="packForm.box_type" placeholder="请填写箱型" show-word-limit> </el-input>-->
        <!--  </el-form-item>-->
        <!--</el-col>-->
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-form-item label="备注" prop="pack_remarks">
            <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="packForm.pack_remarks" size="mini" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="vg_mb_8 vg_mt_8">
          <el-button type="primary" size="small" @click="tranDataVisible = true">导入</el-button>
          <el-button type="danger" size="small" @click="delSubTableRow()">删除</el-button>
          <el-button type="warning" size="small" @click="split()">拆分</el-button>
        </div>
        <DynamicUTable
          ref="multiTable"
          :tableData="packForm.packProdList"
          :columns="subTableProperties"
          :is-show="isShow"
          @selection-change="handleSelectionChange"
          :needPagination="false"
          :needSearch="false"
        />
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="导入出运明细" :visible.sync="tranDataVisible" width="50%" @opened="getTranDialogData">
      <el-button type="primary" size="small" @click="importTranDataConfirm">确认选择</el-button>
      <DynamicUTable
        v-if="tranDataVisible"
        ref="tranDialog"
        :is-show="isShow"
        :tableData="tranDialogTableData"
        :columns="tranDialogTableProperties"
        @selection-change="tranDialogSelectionChange"
        :needPagination="false"
        :needSearch="false"
      />
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { packAPI } from '@api/modules/pack';
import { stffAPI } from '@/api/modules/staff';
import { suppAPI } from '@api/modules/supp';
import { userAPI } from '@api/modules/user';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { cpttAPI } from '@api/modules/comptitle';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { subTableProperties, tranDialogTableProperties } from '@/views/TransportationManagement/PackManage/pack';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';
import helper from '@assets/js/helper';

export default {
  name: 'PackEditMain',
  components: {
    DynamicUTable,
    editHeader,
    inputUser
  },
  data() {
    return {
      tranDialogTableData: [],
      tranDialogTableProperties: tranDialogTableProperties,
      subTableProperties: subTableProperties,
      rules: {
        customs_no: [{ required: true, trigger: 'blur', message: ' ' }],
        tran_spdate: [{ required: true, trigger: 'blur', message: ' ' }],
        agent_id: [{ required: true, trigger: 'blur', message: ' ' }],
        pack_date: [{ required: true, trigger: 'blur', message: ' ' }],
        box_type: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      packForm: {},
      show: false,
      isShow: true,
      btn: {},
      user_id: null, // 录入人id
      stffForm: {}, // 查询到的录入人信息
      loadingFlag: true,
      tranDataVisible: false,
      tranDataVisible1: false,
      title: '委托设计单',
      packStppList: [],
      suppOptionList: [], // 供应商信息
      selectList: [],
      instOptionList: [], // 送货信息
      userList: [], // 经办人信息
      CpttsOptins: [], // 公司信息
      type: null,
      synchronizationFlag: false,
      dialogClauInfoVisible: false,
      subTableMap: new Map(),
      subSelection: []
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = false;
      this.getPackInfo();
      // this.getStaffId();
      // this.getSupps();
      this.getUser();
      this.getCpttsList();
    },
    // 判断是否为出运刚生成的进仓单
    editStatus() {
      if (this.packForm.create_time === this.packForm.update_time) {
        this.isShow = false;
        this.$emit('isShow', this.isShow);
      }
    },
    // 获取表单信息
    getPackInfo() {
      let { form_id } = JSON.parse(UrlEncode.decode(this.$route.query.key));
      packAPI.getPackById({ pack_id: form_id }).then(({ data }) => {
        this.packForm = data.form;
        this.btn = data.btn;
        this.user_id = data.form.user_id;
        this.user_name = data.form.stff_name;
        this.stffForm.stff_name = data.form.stff_name;
        this.stffForm.dept_name = data.form.dept_name;
        this.stffForm.dept_team_name = data.form.dept_team_name;
        // this.getUserById();
        // this.editStatus();
      });
    },
    // 供应商简称选值
    suppIdChange() {
      let { supp_abbr, supp_addr } = this.suppOptionList.find(({ supp_id }) => supp_id === this.packForm.supp_id);
      this.packForm.supp_abbr = supp_abbr;
      this.packForm.pack_caddr = supp_addr;
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', true);
          this.show = true;
          this.loadingFlag = false;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              return false;
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      this.packForm.packProdList = this.packForm.packProdList.concat(Array.from(this.subTableMap.values()));

      packAPI.editPack(this.packForm).then(({ data }) => {
        this.$message('保存成功');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.loadingFlag = false;
        this.initData();
      });
    },
    // 查询经办人
    getUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            let userList = res.data.data;
            this.userList = userList;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getStaffId() {
      get(packAPI.getDeptInfo)
        .then(res => {
          this.userList = res.data.data;
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    // 根据录入人id查询录入人信息
    getUserById() {
      get(userAPI.getUserById, {
        user_id: this.user_id
      })
        .then(res => {
          if (res.data.code === 0) {
            this.stffForm = res.data.data.form;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.loadingFlag = false;
      this.initData();
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.loadingFlag = false;
          this.getPackInfo();
          this.getUser();
          this.getSupps();
          this.getInsts();
          this.getCpttsList();
          //this.resetForm("packForm");
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取供应商信息
    getSupps() {
      get(suppAPI.getSuppsV1).then(res => {
        this.suppOptionList = res.data.data;
      });
    },
    // 获取公司抬头
    getCpttsList() {
      get(cpttAPI.getAllCpttsV1, {}).then(res => {
        this.CpttsOptins = res.data.data;
      });
    },
    // 选中值
    handleSelectionChange(val) {
      this.selectList = val;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //导出excel
    clickExportPartExcel(type) {
      this.$confirm(`确定${type === 0 ? '打印预览?' : '下载Excel?'}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch('/api/tran/pdf/exportTuo', { pack_id: this.packForm.pack_id, type: type }).then(res => {
            if (type === 0) {
              window.open('https://view.officeapps.live.com/op/view.aspx?src=' + helper.megPath(res.data.pdf_url));
            } else {
              this.helper.downloadItemExcel(res.data.pdf_url, `${res.data.fileName}.xls`);
            }
          });
        })
        .catch(() => {});
    },
    delSubTableRow() {
      if (this.selectList.length === 0) return this.$message.warning('请至少选择一条数据!');
      let selectIds = Array.from(this.selectList, ({ pack_prod_id }) => pack_prod_id);
      let packProdListIds = Array.from(this.packForm.packProdList, ({ pack_prod_id }) => pack_prod_id);
      if (selectIds.length === packProdListIds.length) return this.$message.warning('最少保留一条原数据!');
      let { tableData, map } = deleteTableRow('pack_prod_id', this.packForm.packProdList, this.subTableMap, this.selectList);
      this.packForm.packProdList = tableData;
      this.subTableMap = map;
    },
    tranDialogSelectionChange(val) {
      this.subSelection = val;
    },
    getTranDialogData() {
      let set = [...new Set(Array.from(cloneDeep(this.packForm.packProdList), ({ supp_id }) => supp_id))];
      packAPI.getTranPordById({ supp_id: set[0], tran_id: this.packForm.tran_id }).then(({ data }) => {
        this.tranDialogTableData = data.list;
      });
    },
    importTranDataConfirm() {
      this.subSelection.forEach(item => {
        let find = this.packForm.packProdList.find(({ tran_prod_id }) => tran_prod_id === item.tran_part_id);
        if (!find) {
          item.tran_prod_id = item.tran_part_id;
          this.packForm.packProdList.push(item);
        }
      });
      this.$refs.tranDialog.clearSelection();
      this.tranDataVisible = false;
    },
    split() {
      if (this.selectList.length !== 1) return this.$message.warning('请选择一条数据!');
      this.$prompt('', '请输入拆分数量', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\d.]+$/,
        inputErrorMessage: '请输入正确的数量',
        closeOnClickModal: false,
        inputValue: 1
      }).then(({ value }) => {
        let splitCopy1 = this.selectList[0];
        if (value >= splitCopy1.prod_box_num) return this.$message.warning('拆分的数量不能大于等于原数量!');
        splitCopy1.prod_box_num -= value;
        splitCopy1.prod_tgweight = splitCopy1.prod_box_num * splitCopy1.prod_gweight;
        splitCopy1.prod_tvol = splitCopy1.prod_box_num * splitCopy1.prod_vol;
        splitCopy1.prod_ship = splitCopy1.prod_box_num * splitCopy1.prod_qpc;
        let splitCopy2 = cloneDeep(splitCopy1);
        splitCopy2.pack_prod_id = null;
        splitCopy2.prod_box_num = value;
        splitCopy2.prod_tgweight = splitCopy2.prod_box_num * splitCopy2.prod_gweight;
        splitCopy2.prod_tvol = splitCopy2.prod_box_num * splitCopy2.prod_vol;
        splitCopy2.prod_ship = splitCopy2.prod_box_num * splitCopy2.prod_qpc;
        this.packForm.packProdList.push(splitCopy2);
        this.$refs.multiTable.clearSelection();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_bort {
  border-top: dashed 1px #dcdfe6;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_export3 {
  position: absolute;
  top: 0;
  left: 255px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.el-form-item {
  margin-bottom: 3px;
}
</style>
