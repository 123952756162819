import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep6Decimal, keepNumber } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '装箱日期',
    prop: 'pack_date',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '关单号', prop: 'customs_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '离港日',
    prop: 'tran_spdate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '经办人',
    prop: 'agent_id',
    itemType: 'select',
    options: [],
    input: true,
    widthAuto: true,
    needOtherColumn: true,
    formatter: val => val.stff_name
  },
  store.state.status
];
export const subTableProperties = [
  { label: '供应商', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  { label: '新客户货号', prop: 'prod_new_cust_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '原始订单号', prop: 'scon_cust_ono', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '新订单号', prop: 'scon_cust_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '箱数', prop: 'prod_box_num', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '总毛重', prop: 'prod_tgweight', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '总体积', prop: 'prod_tvol', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '出运数量',
    prop: 'prod_ship',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      input: (val, row) => (row.prod_ship = keep6Decimal(val)),
      maxLength: 10,
      change: (val, row) => (row.prod_ship = new BigNumber(val).toFixed(2, 0))
    }
  },
  {
    label: '箱型',
    prop: 'box_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      maxLength: 20
    }
  }
];
export const tranDialogTableProperties = [
  { label: '供应商', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
